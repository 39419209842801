import * as React from "react";
import Layout from "../components/layout";
import Head from "../components/head";
import { graphql, useStaticQuery } from "gatsby";

const SignupConfirmed = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          author
        }
      }
    }
  `);

  return (
    <>
      <Head title={`Signup confirmed | ${data.site.siteMetadata.title}`} />
      <Layout>
        <main className="mx-auto my-10 max-w-7xl px-4 sm:my-12 sm:px-6 md:my-16 lg:my-20 lg:px-8 xl:my-28">
          <div className="sm:text-center">
            <h1 className="text-4xl font-bold tracking-tight text-slate-900 sm:text-5xl md:text-6xl">
              Your subscription is confirmed{" "}
              <span role="img" aria-label="emoji">
                🙌
              </span>
            </h1>
            <p className="mt-3 text-base text-slate-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-auto">
              Your signup is confirmed! Open your email client and in a few
              minutes there'll be amazing content waiting for you.
            </p>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default SignupConfirmed;
